import * as ENV from './Config.js';
import { useHistory } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Alert from './Alert'
import ButtonSubmit from './ButtonSubmit'
import AccountLoading from './AccountLoading'
import { setToken, setUsername, setEmail, getToken, removeUserSession } from './Utils/Common'
//Banderas
import AcountAuthentication from '../images/undraw_authentication.svg'
import AcountCurrency from '../images/undraw_currency.svg'
import AcountProfile from '../images/undraw_profile.svg'


const Account = ({pais}) =>  {

    let history = useHistory();

    const [nombres, setNombres] = useState("");
    const [apellido_paterno, setApellido_paterno] = useState("");
    const [apellido_materno, setApellido_materno] = useState("");
    const [telefono, setTelefono] = useState("");
    const [correo, setCorreo] = useState("");

    const [user_compraria_tc_aprox, setUser_compraria_tc_aprox] = useState("");
    const [user_compraria_monto, setUser_compraria_monto] = useState("");
    const [user_venderia_tc_aprox, setUser_venderia_tc_aprox] = useState("");
    const [user_venderia_monto, setUser_venderia_monto] = useState("");
    const [check_correo, setCheck_correo] = useState(false);
    const [check_sms, setCheck_sms] = useState(false);
    const [check_whassapp, setCheck_whassapp] = useState(false);
    const [check_telegram, setCheck_telegram] = useState(false);

    const [oldcontrasena, setOldcontrasena] = useState("");
    const [contrasena, setContrasena] = useState("");
    const [recontrasena, setRecoctrasena] = useState("");

    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [messageCheck, setMessageCheck] = useState("");
    

    function changeCheck(value) {
      if (value === "1") {
        return true;
      } else if (value === "") {
        return false;
      } else if (value === true) {
        return "1";
      } else if (value === false) {
        return "";
      }
    }

    const handleChangeChek = (field) => (event) => {
      if (field === "SMS") {
        if (telefono === "" & check_sms === false) {
          setMessageCheck("Para seleccionar WhatsApp, Telegram o SMS debe ingresar su teléfono")
        } else {
          setCheck_sms(!check_sms)
          setMessageCheck("")
        }
      }
      if (field === "WhatsApp") {
        if (telefono === "" & check_whassapp === false) {
          setMessageCheck("Para seleccionar WhatsApp, Telegram o SMS debe ingresar su teléfono")
        } else {
          setCheck_whassapp(!check_whassapp)
          setMessageCheck("")
        }
      }
      if (field === "Telegram") {
        if (telefono === "" & check_telegram === false) {
          setMessageCheck("Para seleccionar WhatsApp, Telegram o SMS debe ingresar su teléfono")
        } else {
          setCheck_telegram(!check_telegram)
          setMessageCheck("")
        }
      }
    }

    const handleSubmitUser = (event) => {
        event.preventDefault();
        if (true) {
          setLoading(true);
          ENV.API_HEADERS_POST.headers['Authorization'] = "Bearer "+getToken();
          ENV.API_HEADERS_POST.body = JSON.stringify([{
            cod_pais: pais.toUpperCase(),
            nombres: nombres,
            apellido_paterno: apellido_paterno,
            apellido_materno: apellido_materno,
            telefono: telefono,
            user_compraria_tc_aprox: user_compraria_tc_aprox,
            user_compraria_monto: user_compraria_monto,
            user_venderia_tc_aprox: user_venderia_tc_aprox,
            user_venderia_monto: user_venderia_monto,
            check_correo: changeCheck(check_correo),
            check_sms: changeCheck(check_sms),
            check_whassapp: changeCheck(check_whassapp),
            check_telegram: changeCheck(check_telegram)
          }]);
          fetch(ENV.API_URL_UPDATEACCOUNT, ENV.API_HEADERS_POST)
          .then(async response => await response.json())
          .then(result => {
            setMessage(ENV.ALERT(result.SP_STATUS, result.SP_MSJ_USER));
            setLoading(false);
            if (result.SP_STATUS === 'OK') setUsername(nombres);
          })
          .catch(error => {
            setMessage(ENV.MESSAGE_ALERT.ERROR_SERVIDOR);
            setLoading(false);
          });
        } else {
          setMessage(ENV.MESSAGE_ALERT.ERROR_CLIENTE);
          setLoading(false);
        }
    }

    const handleSubmitPassword = (event) => {
        event.preventDefault();
        if (oldcontrasena.length > 0 & contrasena.length > 0 & recontrasena.length > 0) {
          if (contrasena === recontrasena) {
            setLoading(true);
            ENV.API_HEADERS_POST.headers['Authorization'] = "Bearer "+getToken();
            ENV.API_HEADERS_POST.body = JSON.stringify([{
              old_contrasena: oldcontrasena,
              new_contrasena: contrasena
            }]);
            fetch(ENV.API_URL_CHANGEPASSWORD, ENV.API_HEADERS_POST)
            .then(async response => await response.json())
            .then(result => {
              setMessage(ENV.ALERT(result.SP_STATUS, result.SP_MSJ_USER));
              setLoading(false);
              if (result.SP_STATUS === 'OK') {
                setOldcontrasena("");
                setContrasena("");
                setRecoctrasena("");
              }
            })
            .catch(error => {
              setMessage(ENV.MESSAGE_ALERT.ERROR_SERVIDOR);
              setLoading(false);
            });
          } else {
            setMessage(ENV.MESSAGE_ALERT.ERROR_PASSWORD_DIFF);
            setLoading(false);
          }
        } else {
          setMessage(ENV.MESSAGE_ALERT.ERROR_CLIENTE);
          setLoading(false);
        }
    }

    useEffect(async () => {
      const fetchUser = async () => {
          ENV.API_HEADERS_GET.headers['Authorization'] = "Bearer "+getToken();
          fetch(ENV.API_URL_ACCOUNT, ENV.API_HEADERS_GET)
          .then(async response => await response.json())
          .then(result => {
            //perfil
            setNombres(result.nombres);
            setCorreo(result.correo);
            setApellido_paterno(result.apellido_paterno);
            setApellido_materno(result.apellido_materno);
            setTelefono(result.telefono);
            //alerta
            setUser_compraria_tc_aprox(result.user_compraria_tc_aprox);
            setUser_compraria_monto(result.user_compraria_monto.replace(",", ""));
            setUser_venderia_tc_aprox(result.user_venderia_tc_aprox);
            setUser_venderia_monto(result.user_venderia_monto.replace(",", ""));
            setCheck_correo(changeCheck(result.check_correo));
            setCheck_sms(changeCheck(result.check_sms));
            setCheck_whassapp(changeCheck(result.check_whassapp));
            setCheck_telegram(changeCheck(result.check_telegram));
          })
          .catch(error => console.error(error));
      };
      fetchUser();
    }, []);

    return (
        <section className="pb-3 pb-md-5 px-3 p-md-0 bg-light">
          <div className="container pt-5">
                <div className="card">
                  <div className="card-header bg-transparent">
                    <ul className="nav nav-pills" id="pills-tab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link d-flex active" id="alertas-tab" data-bs-toggle="pill" data-bs-target="#alertas" type="button" role="tab" aria-controls="alertas" aria-selected="true">
                          <i className="bi bi-bell me-2"></i> <div className="d-none d-sm-block">Notificación</div>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link d-flex" id="perfil-tab" data-bs-toggle="pill" data-bs-target="#perfil" type="button" role="tab" aria-controls="perfil" aria-selected="true">
                          <i className="bi bi-person me-2"></i> <div className="d-none d-sm-block">Perfil</div>
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link d-flex" id="contrasena-tab" data-bs-toggle="pill" data-bs-target="#contrasena" type="button" role="tab" aria-controls="contrasena" aria-selected="true">
                          <i className="bi bi-key me-2"></i> Contraseña
                        </button>
                      </li>
                    </ul>
                  </div>
                  <div className="card-body p-4 row">
                    {
                      message &&
                      <Alert message={message} setMessage={setMessage}/>
                    }
                    <div className="tab-content col-12 order-first order-md-last" id="pills-tabContent">
                      <div className="tab-pane fade show active" id="alertas" role="tabpanel" aria-labelledby="alertas-tab">
                        <div className="row">
                          <div className="col-12 col-md-7">
                            {
                              (correo) ?
                              <form className="row g-3" onSubmit={handleSubmitUser}>
                                <div className="row">
                                    <div className="col-12 my-2">
                                      <p className="fs-6 my-0">Deseo <span className="badge bg-light text-dark">Comprar Dólares</span> Notificarme cuando el tipo de cambio esté en:</p>
                                    </div>
                                    <div className="col-12 col-md-6 my-2">
                                        <div className="form-floating">
                                          <input type="number" className="form-control" value={user_compraria_tc_aprox} onChange={(e) => setUser_compraria_tc_aprox(e.target.value)}/>
                                          <label>Tipo de cambio</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 my-2">
                                        <div className="form-floating">
                                          <input type="number" className="form-control" value={user_compraria_monto} onChange={(e) => setUser_compraria_monto(e.target.value)}/>
                                          <label>Monto a comprar</label>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <p className="fs-6 my-0">Deseo <span className="badge bg-light text-dark">Vender Dólares</span> Notificarme cuando el tipo de cambio esté en:</p>
                                    </div>
                                    <div className="col-12 col-md-6 my-2">
                                        <div className="form-floating">
                                          <input type="number" className="form-control" value={user_venderia_tc_aprox} onChange={(e) => setUser_venderia_tc_aprox(e.target.value)}/>
                                          <label>Tipo de cambio</label>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-6 my-2">
                                        <div className="form-floating">
                                          <input type="number" className="form-control" value={user_venderia_monto} onChange={(e) => setUser_venderia_monto(e.target.value)}/>
                                          <label>Monto a vender</label>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                      <p className="fs-6 my-0">Notificarme por estos medios</p>
                                    </div>
                                    <div className="col-12 my-2">
                                      <div className="input-group mb-3">
                                        <div className="input-group-text col-2 col-md-auto">
                                          <input className="form-check-input mt-0" type="checkbox" checked={check_correo} onChange={() => setCheck_correo(!check_correo)}/>
                                        </div>
                                        <span className="input-group-text col-10 col-md-auto">Correo</span>
                                        <input type="text" className="form-control col-12 col-md-9" value={correo} disabled readonly/>
                                      </div>
                                      <div className="input-group mb-3">
                                        <div className="input-group-text col-2 col-md-auto">
                                          <input className="form-check-input mt-0" type="checkbox" checked={check_whassapp} onChange={handleChangeChek("WhatsApp")}/>
                                        </div>
                                        <span className="input-group-text col-10 col-md-auto">WhatsApp</span>
                                        <div className="input-group-text col-2 col-md-auto">
                                          <input className="form-check-input mt-0" type="checkbox" checked={check_telegram} onChange={handleChangeChek("Telegram")}/>
                                        </div>
                                        <span className="input-group-text col-10 col-md-auto">Telegram</span>
                                        <div className="input-group-text col-2 col-md-auto">
                                          <input className="form-check-input mt-0" type="checkbox" checked={check_sms} onChange={handleChangeChek("SMS")}/>
                                        </div>
                                        <span className="input-group-text col-3 col-md-auto">SMS</span>
                                        <input type="text" className="form-control col-12" placeholder="Teléfono" value={telefono} onChange={(e) => setTelefono(e.target.value)}/>
                                      </div>
                                      <p class="text-danger">{messageCheck}</p>
                                    </div>
                                    <div className="gap-2 mt-3">
                                      <ButtonSubmit value={'Actualizar Notificación'} loading={loading}/>
                                    </div>
                                </div>
                              </form>
                              :
                              <AccountLoading/>
                            }
                          </div>
                          <div className="col-0 col-md-5 align-self-center text-center d-none d-sm-block">
                            <img src={AcountCurrency} className="img-fluid p-5" style={{maxHeight: "300px"}} />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="perfil" role="tabpanel" aria-labelledby="perfil-tab">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            {
                              (correo) ?
                              <form className="g-3" onSubmit={handleSubmitUser}>
                                <div className="mb-3 row">
                                  <div className="col-12 my-2">
                                      <div className="form-floating">
                                        <input type="text" className="form-control" value={nombres} onChange={(e) => setNombres(e.target.value)}/>
                                        <label>Nombres</label>
                                      </div>
                                  </div>
                                  <div className="col-12 my-2">
                                      <div className="form-floating">
                                        <input type="text" className="form-control" value={apellido_paterno} onChange={(e) => setApellido_paterno(e.target.value)}/>
                                        <label>Apellido Paterno</label>
                                      </div>
                                  </div>
                                  <div className="col-12 my-2">
                                      <div className="form-floating">
                                        <input type="text" className="form-control" value={apellido_materno} onChange={(e) => setApellido_materno(e.target.value)}/>
                                        <label>Apellido Materno</label>
                                      </div>
                                  </div>
                                  <div className="col-12 my-2">
                                      <div className="form-floating">
                                        <input type="tel" className="form-control" value={telefono} onChange={(e) => setTelefono(e.target.value)}/>
                                        <label>Telefono</label>
                                      </div>
                                  </div>
                                  <div className="gap-2 mt-3">
                                      <ButtonSubmit value={'Actualizar Perfil'} loading={loading}/>
                                  </div>
                                </div>
                              </form>
                              :
                              <AccountLoading/>
                            }
                          </div>
                          <div className="col-0 col-md-6 align-self-center text-center d-none d-sm-block">
                            <img src={AcountProfile} className="img-fluid p-5" style={{maxHeight: "300px"}} />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="contrasena" role="contrasena" aria-labelledby="perfil-tab">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            {
                              (correo) ?
                              <form className="g-3" onSubmit={handleSubmitPassword}>
                                <div className="mb-3 row">
                                  <div className="col-12 my-2">
                                      <div className="form-floating">
                                        <input type="password" className="form-control" value={oldcontrasena} onChange={(e) => setOldcontrasena(e.target.value)} required/>
                                        <label>Contraseña actual</label>
                                      </div>
                                  </div>
                                  <div className="col-12 my-2">
                                      <div className="form-floating">
                                        <input type="password" className="form-control" value={contrasena} onChange={(e) => setContrasena(e.target.value)} required/>
                                        <label>Contraseña nueva</label>
                                      </div>
                                  </div>
                                  <div className="col-12 my-2">
                                      <div className="form-floating">
                                        <input type="password" className="form-control" value={recontrasena} onChange={(e) => setRecoctrasena(e.target.value)} required/>
                                        <label>Repetir contraseña nueva</label>
                                      </div>
                                  </div>
                                  <div className="gap-2 mt-3">
                                      <ButtonSubmit value={'Actualizar Contraseña'} loading={loading}/>
                                  </div>
                                </div>
                              </form>
                              :
                              <AccountLoading/>
                            }
                          </div>
                          <div className="col-0 col-md-6 align-self-center text-center d-none d-sm-block">
                            <img src={AcountAuthentication} className="img-fluid p-5" style={{maxHeight: "300px"}} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

          </div>
        </section>
    )
}

export default Account

//<div className="form-check form-check-inline">
//  <input className="form-check-input" type="checkbox" id="inlineCheckbox1" checked={check_correo} onChange={() => setCheck_correo(!check_correo)}/>
//  <label className="form-check-label" for="inlineCheckbox1">Correo</label>
//</div>
//<div className="form-check form-check-inline">
//  <input className="form-check-input" type="checkbox" id="inlineCheckbox2" checked={check_sms} onChange={() => setCheck_sms(!check_sms)}/>
//  <label className="form-check-label" for="inlineCheckbox2">SMS</label>
//</div>
//<div className="form-check form-check-inline">
//  <input className="form-check-input" type="checkbox" id="inlineCheckbox3" checked={check_whassapp} onChange={() => setCheck_whassapp(!check_whassapp)}/>
//  <label className="form-check-label" for="inlineCheckbox3">WhatsApp</label>
//</div>