import Header from '../components/Header'
import Privacy from '../components/Privacy'
import Footer from '../components/Footer'

const Privacidad = ({id}) => {

    return (
        <>
        	<Header pais={id}/>
        	<Privacy/>
            <Footer pais={id}/>
        </>
    )
}

export default Privacidad