import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import Register from '../components/Register'
import Subscribe from '../components/Subscribe'
import Footer from '../components/Footer'

const Registro = ({id}) => {
	//let { id } = useParams();

    return (
        <>
        	<Header pais={id}/>
            <Register pais={id}/>
            <Footer pais={id}/>
        </>
    )
}

export default Registro