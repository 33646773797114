

// return the token from the session storage
export const getToken = () => {
  return localStorage.getItem('token') || null;
}

// return the user data from the session storage
export const getUsername = () => {
  return localStorage.getItem('username') || null;
}

// return the user data from the session storage
export const getEmail = () => {
  return localStorage.getItem('email') || null;
}

// set the token and user from the session storage
export const setToken = (token) => {
  localStorage.setItem('token', token);
}

// set the token and user from the session storage
export const setUsername = (nombres) => {
  localStorage.setItem('username', nombres);
}

// set the token and user from the session storage
export const setEmail = (correo) => {
  localStorage.setItem('email', correo);
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('username');
  localStorage.removeItem('email');
}
