import * as ENV from './Config.js';
import React, { useState, useEffect } from 'react'
import fetch from 'cross-fetch';
import WelcomeLoading from '../components/WelcomeLoading'
import { getToken } from './Utils/Common'


const Welcome = ({pais, country_source, country_target, dataNow}) => {

  //const [data, setData] = useState(null);
  const [inputFrom, setInputFrom] = useState(100);
  const [country, setCountry] = useState([country_source, country_target]);

  const onCurrencyChange = (key) => {
    if (country[0]===country_source) {
      setCountry([country_target, country_source])
    }
    if (country[0]===country_target) {
      setCountry([country_source, country_target])
    }
  };

  function get_tipocambio() {
    var cambio = 0;
    if (dataNow != null) {
      if (country[0]===country_source) {
        cambio = dataNow.maxmin.venta.dolar_venta
      }
      if (country[0]===country_target) {
        cambio = dataNow.maxmin.compra.dolar_compra
      }
      return cambio
    }
  }

  function get_date() {
    var date;
    if (dataNow != null) {
      if (country[0]===country_source) {
        date = dataNow.maxmin.compra.fecha_hora_cotizacion
      }
      if (country[0]===country_target) {
        date = dataNow.maxmin.venta.fecha_hora_cotizacion
      }
      return date
    }
  }

  function fnt_inputTo() {
    var cambio = 0;
    if (dataNow != null) {
      if (country[0]===country_source) {
        cambio = 1/get_tipocambio()
      }
      if (country[0]===country_target) {
        cambio = 1*get_tipocambio()
      }
      return fnt_amount(inputFrom*cambio)
    }
  }
  
  function fnt_dateTo() {
    var options = {year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric'};
    if (dataNow != null) {
      return new Intl.DateTimeFormat('es-ES', options).format(new Date(get_date()))
    }
  }

  function fnt_amount(number, decimal=2) {
    return number.toFixed(decimal);
  }


  //useEffect(async () => {
  //  const fetchCurrency = async () => {
  //      fetch(ENV.API_URL_MAXMIN+pais.toUpperCase(), ENV.API_HEADERS_GET)
  //      .then(async response => await response.json())
  //      .then(result => {
  //        if (result.length === 0 || Object.keys(result).length === 0) {
  //          setData(null);
  //        } else {
  //          setData(result);
  //        }
  //      })
  //      .catch(error => console.error(error));
  //  };
  //  const timer = setTimeout(() => {
  //    fetchCurrency();
  //  }, 1000);
  //  const interval = setInterval(() => {
  //    fetchCurrency();
  //  }, 1000*60*2);
  //  return () => clearInterval(interval);
  //}, []);

  return (
    <>
      <section className="bg-dark" style={{backgroundImage: `url(Simple-Shiny.svg)`, backgroundRepeat: 'no-repeat', backgroundPosition: 'center center'}}>
        <div className="container py-3 py-md-4">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 text-center text-md-start">
              <h6 className="pt-2 pt-md-4 text-white">
                - Dolarti - {ENV.CURRENCY_OPTIONS[country_source].name}
              </h6>
              <h1 className="display-4 fw-bold text-white mb-3">
                Compara y encuentra <strong className="text-primary fw-bold">el mejor tipo de cambio</strong>
              </h1>
              <p className="fs-6 mb-4 text-white">
                Ademas recibe notificaciones personalizadas con el mejor tipo de cambio
              </p>
              <div className="mb-4">
                {
                  getToken() ? 
                  <a className="btn btn-lg btn-secondary rounded-pill py-2 px-4 me-3" href={"/"+pais+"/cuenta"}>
                    Configurar Notificación
                  </a>
                  : 
                  <>
                  <a className="btn btn-lg btn-secondary rounded-pill py-2 px-4 me-3" href={"/"+pais+"/registro"}>
                    Registrate ahora
                  </a>
                  <a className="btn btn-lg btn-light rounded-pill py-2 px-4" href={"/"+pais+"/ingresar"}>
                    Ingresar
                  </a>
                  </>
                }
              </div>
            </div>
            <div className="col-12 col-md-6 bg-white p-3">
              <div className="card border-0">

                <div className="card-body mx-2 my-1">
                    {
                      (dataNow == null) ? 
                          <WelcomeLoading/>
                          : 
                          <div className="row align-items-center">

                            <div className="col-12">
                                <div className="row">
                                  <div className="col-8 px-0 border rounded-start">
                                    <div className="row px-3">
                                      <div className="col-12">
                                        <small className="opacity-75">Tengo</small>
                                      </div>
                                      <div className="col-auto fs-4 my-auto">
                                        {ENV.CURRENCY_OPTIONS[country[0]].currency}
                                      </div>
                                      <div className="col my-auto">
                                        <input type="number" defaultValue={inputFrom} onChange={(e) => setInputFrom(e.target.value)} className="form-control input-outline-none bg-transparent border-0 fs-4 p-0" placeholder="0.00"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4 bg-primary text-white fw-bold border-top border-end border-bottom rounded-end d-flex align-items-center justify-content-center" style={{height: 70}}>
                                    <img height="20" src={ENV.CURRENCY_OPTIONS[country[0]].src} className="me-2"/>
                                    {ENV.CURRENCY_OPTIONS[country[0]].label}
                                  </div>
                                </div>
                            </div>

                            <div className="col-12 btn-change-container">
                              <button className="btn btn-lg p-2 bg-white rounded-circle" onClick={onCurrencyChange}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="2.2rem" height="2.2rem" fill="currentColor" class="bi bi-arrow-repeat" viewBox="0 0 16 16">
                                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                  <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                </svg>
                              </button>
                            </div>

                            <div className="col-12 mt-3">
                                <div className="row">
                                  <div className="col-8 px-0 border rounded-start">
                                    <div className="row px-3">
                                      <div className="col-12">
                                        <small className="opacity-75">*Obtengo</small>
                                      </div>
                                      <div className="col-auto fs-4 my-auto">
                                        {ENV.CURRENCY_OPTIONS[country[1]].currency}
                                      </div>
                                      <div className="col my-auto">
                                        <input type="number" value={fnt_inputTo()} className="form-control input-outline-none bg-transparent border-0 fs-4 p-0" placeholder="0.00"/>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-4 bg-secondary text-white fw-bold border-top border-end border-bottom rounded-end d-flex align-items-center justify-content-center" style={{height: 70}}>
                                    <img height="20" src={ENV.CURRENCY_OPTIONS[country[1]].src} className="me-2"/>
                                    {ENV.CURRENCY_OPTIONS[country[1]].label}
                                  </div>
                                </div>
                            </div>

                          </div>
                    }
                </div>
              </div>
                {
                  (dataNow == null) ?
                  ''
                  :
                  <div className="row align-items-center mx-2">
                    <div className="col-12 text-center">
                      * Según el mejor tipo de cambio<span className="badge bg-light text-dark">Compra {dataNow.maxmin.compra.dolar_compra}</span> | <span className="badge bg-light text-dark">Venta {dataNow.maxmin.venta.dolar_venta}</span>
                    </div>
                    <div className="col-12 text-center text-dark text-opacity-75">
                      Obtenido el {fnt_dateTo()}
                    </div>
                  </div>
                }
            </div>
          </div>
        </div>
      </section>

      <section className="bg-dark bg-opacity-75">
        <div className="container py-4 pt-md-3 pb-md-1">
          <div className="row align-items-center text-center text-white">
            <div className="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" fill="currentColor" className="bi bi-envelope" viewBox="0 0 16 16">
                <path fillRule="evenodd" d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
              </svg>
              <p className="fs-6 mt-2 d-none d-sm-block">Correo</p>
            </div>
            <div className="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" fill="currentColor" className="bi bi-chat-right-text" viewBox="0 0 16 16">
                <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"/>
                <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
              </svg>
              <p className="fs-6 mt-2 d-none d-sm-block">SMS</p>
            </div>
            <div className="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" fill="currentColor" className="bi bi-whatsapp" viewBox="0 0 16 16">
                <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
              </svg>
              <p className="fs-6 mt-2 d-none d-sm-block">WhatsApp</p>
            </div>
            <div className="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" fill="currentColor" className="bi bi-facebook" viewBox="0 0 16 16">
                <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z"/>
              </svg>
              <p className="fs-6 mt-2 d-none d-sm-block">Facebook</p>
            </div>
            <div className="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" fill="currentColor" className="bi bi-telegram" viewBox="0 0 16 16">
                <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
              </svg>
              <p className="fs-6 mt-2 d-none d-sm-block">Telegram</p>
            </div>
            <div className="col">
              <svg xmlns="http://www.w3.org/2000/svg" width="1.8em" height="1.8em" fill="currentColor" className="bi bi-globe2" viewBox="0 0 16 16">
                <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855-.143.268-.276.56-.395.872.705.157 1.472.257 2.282.287V1.077zM4.249 3.539c.142-.384.304-.744.481-1.078a6.7 6.7 0 0 1 .597-.933A7.01 7.01 0 0 0 3.051 3.05c.362.184.763.349 1.198.49zM3.509 7.5c.036-1.07.188-2.087.436-3.008a9.124 9.124 0 0 1-1.565-.667A6.964 6.964 0 0 0 1.018 7.5h2.49zm1.4-2.741a12.344 12.344 0 0 0-.4 2.741H7.5V5.091c-.91-.03-1.783-.145-2.591-.332zM8.5 5.09V7.5h2.99a12.342 12.342 0 0 0-.399-2.741c-.808.187-1.681.301-2.591.332zM4.51 8.5c.035.987.176 1.914.399 2.741A13.612 13.612 0 0 1 7.5 10.91V8.5H4.51zm3.99 0v2.409c.91.03 1.783.145 2.591.332.223-.827.364-1.754.4-2.741H8.5zm-3.282 3.696c.12.312.252.604.395.872.552 1.035 1.218 1.65 1.887 1.855V11.91c-.81.03-1.577.13-2.282.287zm.11 2.276a6.696 6.696 0 0 1-.598-.933 8.853 8.853 0 0 1-.481-1.079 8.38 8.38 0 0 0-1.198.49 7.01 7.01 0 0 0 2.276 1.522zm-1.383-2.964A13.36 13.36 0 0 1 3.508 8.5h-2.49a6.963 6.963 0 0 0 1.362 3.675c.47-.258.995-.482 1.565-.667zm6.728 2.964a7.009 7.009 0 0 0 2.275-1.521 8.376 8.376 0 0 0-1.197-.49 8.853 8.853 0 0 1-.481 1.078 6.688 6.688 0 0 1-.597.933zM8.5 11.909v3.014c.67-.204 1.335-.82 1.887-1.855.143-.268.276-.56.395-.872A12.63 12.63 0 0 0 8.5 11.91zm3.555-.401c.57.185 1.095.409 1.565.667A6.963 6.963 0 0 0 14.982 8.5h-2.49a13.36 13.36 0 0 1-.437 3.008zM14.982 7.5a6.963 6.963 0 0 0-1.362-3.675c-.47.258-.995.482-1.565.667.248.92.4 1.938.437 3.008h2.49zM11.27 2.461c.177.334.339.694.482 1.078a8.368 8.368 0 0 0 1.196-.49 7.01 7.01 0 0 0-2.275-1.52c.218.283.418.597.597.932zm-.488 1.343a7.765 7.765 0 0 0-.395-.872C9.835 1.897 9.17 1.282 8.5 1.077V4.09c.81-.03 1.577-.13 2.282-.287z"/>
              </svg>
              <p className="fs-6 mt-2 d-none d-sm-block">Web</p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Welcome

//<p className="card-header bg-light fs-6 fw-normal text-center">
//  Si tienes <strong>{inputFrom} {ENV.CURRENCY_OPTIONS[country[0]].currency_text}</strong> obtendrías hasta <strong>{fnt_inputTo()} {ENV.CURRENCY_OPTIONS[country[1]].currency_text}</strong>
//</p>