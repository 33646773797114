import * as ENV from '../components/Config.js';
import { useParams } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { getToken } from '../components/Utils/Common'
import Header from '../components/Header'
import CardList from '../components/CardList'
import Welcome from '../components/Welcome'
import Subscribe from '../components/Subscribe'
import Footer from '../components/Footer'

const Pais = ({id}) => {
	//let { id } = useParams();

    const [dataNow, setDataNow] = useState(null);

    useEffect(async () => {
      const fetchCurrency = async () => {
          //fetch(ENV.API_URL_NOW+id.toUpperCase(), ENV.API_HEADERS_GET)
          fetch('json/trackingnow.pe.json', ENV.API_HEADERS_GET_JSON)
          .then(async response => await response.json())
          .then(result => {
            if (result.length === 0 || Object.keys(result).length === 0) {
              setDataNow(null);
            } else {
              setDataNow(result);
            }
          })
          .catch(error => console.error(error));
      };
      const timer = setTimeout(() => {
        fetchCurrency();
      }, 1000);
      const interval = setInterval(() => {
        fetchCurrency();
      }, 1000*60*5);
      return () => clearInterval(interval);
    }, []);

    return (
        <>
        	<Header pais={id}/>
            <Welcome pais={id} country_source={id} country_target={'us'} dataNow={dataNow}/>
            <CardList pais={id} dataNow={dataNow}/>
            {
              getToken() ?
              ''
              :
              <Subscribe pais={id}/>
            }
            
            <Footer pais={id}/>
        </>
    )
}

export default Pais