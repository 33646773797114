import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import AccountActivate from '../components/AccountActivate'
import Footer from '../components/Footer'

const ActivarCuenta = ({id}) => {
	let { tokenactivate } = useParams();

    return (
        <>
        	<Header pais={id}/>
            <AccountActivate pais={id} tokenactivate={tokenactivate}/>
            <Footer pais={id}/>
        </>
    )
}

export default ActivarCuenta