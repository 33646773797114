import React from 'react'

const CardLoading = ({mobileType}) =>  {

  const data = [0,1,2,3,4,5,6,7,8,9]

  return (
    <>
    {
      data.map((value) => {
        return (
          <div className="col-12" key={value}>
              <div className="card border-0 rounded-0 border-bottom bg-white">
                <div className="card-body p-2 p-md-1">
                    <div className="row">
                      <div className="col-3 col-md-2 d-flex align-items-center justify-content-center">
                        <div className="placeholder-glow">
                          <svg width="80" height="60">
                            <rect width="80" height="60" className="placeholder w-100"/> 
                          </svg>
                        </div>
                      </div>

                      <div className={"col-7 col-md-4 d-sm-block "+mobileType.compra}>
                        <CardCurrency/>
                      </div>

                      <div className={"col-7 col-md-4 d-sm-block "+mobileType.venta}>
                        <CardCurrency/>
                      </div>

                      <div className="col-2 col-md-2 mx-auto d-flex align-items-center justify-content-center">
                        <a href="#" tabIndex="-1" className="btn btn-secondary disabled placeholder w-50"></a>
                      </div>

                    </div>
                </div>
              </div>
          </div>
        )
      })
    }
    </>
  )

}

class CardCurrency extends React.Component {
    
    render(){
        return (
          <div className="row">
            <div className="col-6 col-md-7 d-flex align-items-center justify-content-center">
              <div className="row text-center d-flex align-items-center justify-content-center">
                <div className="col-12">
                  <p className="fs-4 mb-0 placeholder-glow">
                    <span className="placeholder w-100"></span>
                  </p>
                </div>
                <div className="col">
                  <small className="placeholder-glow">
                    <svg width="50" height="12">
                      <rect width="50" height="12" className="placeholder w-100"/> 
                    </svg> 
                  </small>
                </div>
              </div>
            </div>
            <div className="col-6 col-md-5 d-flex align-items-center justify-content-center ps-0">
              <div className="placeholder-glow">
                <svg width="120" height="40">
                  <rect width="120" height="40" className="placeholder w-100"/> 
                </svg>
              </div>
            </div>
          </div>
        );
    }
}

export default CardLoading