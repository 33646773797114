import Landing from '../components/Landing'

function Home() {

    return (
    	<div style={{height: "100vh"}}>
        	<Landing/>
        </div>
    )
}

export default Home