import { useParams } from 'react-router-dom'
import { withRouter } from "react-router";
import Header from '../components/Header'
import Account from '../components/Account'
import Subscribe from '../components/Subscribe'
import Footer from '../components/Footer'

const Cuenta = ({id}) => {
	//let { id } = useParams();

    return (
        <>
        	<Header pais={id}/>
            <Account pais={id}/>
            <Footer pais={id}/>
        </>
    )
}

export default Cuenta