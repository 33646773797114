import React from 'react'

const Question = () => {

  const list_quiestion = [
    {
      key_title: "flush0",
      question: "¿Qué es Dolarti?",
      key_collapse: "flush0_collapse",
      answer: "Dolarti te permite comparar y encontrar el mejor tipo de cambio en un solo lugar. Además de enviarte notificaciones personalizadas."
    },
    {
      key_title: "flush1",
      question: "¿Qué significa compra y venta?",
      key_collapse: "flush1_collapse",
      answer: "Compra es cuando la casa de cambio online te COMPRA dólares y venta es cuando una casa de cambio online te VENDE dólares."
    },
    {
      key_title: "flush2",
      question: "¿Que tipos de cambio puedo conseguir?",
      key_collapse: "flush2_collapse",
      answer: "El dólar interbancario es el dólar que consigues en las agencias bancarias. Su precio es generalmente mayor al del dólar paralelo. Se conoce como dólar paralelo al dólar que consigues en las casas de cambio o con cambistas. En el Perú, existen casas de cambio físicas y online."
    },
    {
      key_title: "flush4",
      question: "¿Son seguras las casas de cambio online?",
      key_collapse: "flush4_collapse",
      answer: "Sí. Las casas de cambio online suelen estar registradas en la Superintendencia de Banca, Seguros y AFP (SBS). Además, las transacciones y depósitos se realizan a través de entidades autorizadas por la SBS.Otras de las características de las casas de cambio online es que puedes realizar la transacción desde la comodidad de tu casa u oficina, ahorrándote tiempo que sueles perder en las colas o en el tráfico.Finalmente, y tal vez la característica más atractiva para los usuarios de las casas de cambio online es que su tipo de cambio es más competitivo, es decir, que obtienes un mejor tipo de cambio que en las agencias bancarias."
    },
    {
      key_title: "flush5",
      question: "¿Qué casas de cambio online existen?",
      key_collapse: "flush5_collapse",
      answer: "Existen muchas casas de cambio online y esto ha permitido que haya mejores ofertas en el tipo de cambio. Siempre hemos querido mostrar más y mejores opciones a nuestros usuarios. Actualmente, tenemos a más de 20 casas de cambio online de todo el Perú."
    }
  ]

  return (
    <section className="py-5 py-md-6 bg-light">
        <article className="container">
            <div className="row">
                <div className="col-12 col-md-8 offset-md-2">
                    <div className="accordion accordion-flush" id="accordionFlush">
                    {
                      list_quiestion.map((value, key) => {
                          return (
                            <div className="accordion-item" key={key}>
                              <h2 className="accordion-header" id={value.key_title}>
                                  <button className="accordion-button collapsed my-2" type="button" data-bs-toggle="collapse" data-bs-target={"#"+value.key_collapse} aria-expanded="false" aria-controls={value.key_collapse}>
                                    <p className="fs-4 my-0 py-2">{value.question}</p>
                                  </button>
                              </h2>
                              <div id={value.key_collapse} className="accordion-collapse collapse" aria-labelledby={value.key_title} data-bs-parent="#accordionFlush">
                                  <div className="accordion-body">
                                    <p className="fs-5">{value.answer}</p>
                                  </div>
                              </div>
                            </div>
                          )
                      })
                    }
                    </div>
                </div>
              </div>
        </article>
    </section>
  )
}

export default Question