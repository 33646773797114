import React from 'react'
import Header from '../components/Header'

const Error = () => {

  return (
    <>
      <Header/>
      <section className="d-flex h-100 pb-3 pb-md-5 px-3 p-md-0 text-center bg-primary bg-opacity-25">
        <div className="container d-flex w-100 h-100 p-3 mx-auto flex-column">
          <main className="my-auto px-3">
            <h2 className="display-6 fw-bold pt-2 pt-md-4 text-dark">
              ¡ERROR! Esta página no existe.
            </h2>
            <div className="col-lg-7 mx-auto">
              <p className="fs-4 mb-4 text-dark">
                No se ha podido encontrar la página que buscas.
              </p>
            </div>
          </main>
        </div>
      </section>
    </>
  )

}

export default Error