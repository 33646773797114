//Logo
import DolartiLogo from '../images/dolarti_logo.png'

//Banderas
import ImgUs from '../images/banderas/US3x2.svg'
import ImgPe from '../images/banderas/PE3x2.svg'
import ImgCo from '../images/banderas/CO3x2.svg'
import ImgMx from '../images/banderas/MX3x2.svg'
import ImgCl from '../images/banderas/CL3x2.svg'

export const API_URL = "https://apicurrency.vindeolab.com/";//"http://127.0.0.1:5000/";
export const API_VERSION = "api/v1.0/";
export const API_RESOURCE_MAXMIN = "trackingmaxmin/";
export const API_RESOURCE_CHARTHIST = "charthist/";
export const API_RESOURCE_NOW = "trackingnow/";
export const API_RESOURCE_SUSCRIBE = "suscribe/";
export const API_RESOURCE_REGISTER = "register/";
export const API_RESOURCE_UPDATEACCOUNT = "updateaccount/";
export const API_RESOURCE_ACCOUNT = "account/";
export const API_RESOURCE_LOGIN = "login/";
export const API_RESOURCE_RESETPASSWORD = "resetpassword/";
export const API_RESOURCE_FORGOTPASSWORD = "forgotpassword/";
export const API_RESOURCE_CHANGEPASSWORD = "changepassword/";
export const API_RESOURCE_ACCOUNTACTIVATE = "accountactivate/";
export const API_RESOURCE_REDIRECTWEB = "redirectweb/";
export const API_KEY = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.cThIIoDvwdueQB468K5xDc5633seEFoqwxjF_xSJyQQ";

export const API_URL_MAXMIN = API_URL+API_VERSION+API_RESOURCE_MAXMIN;
export const API_URL_CHARTHIST = API_URL+API_VERSION+API_RESOURCE_CHARTHIST;
export const API_URL_NOW = API_URL+API_VERSION+API_RESOURCE_NOW;
export const API_URL_SUSCRIBE = API_URL+API_VERSION+API_RESOURCE_SUSCRIBE;
export const API_URL_REGISTER = API_URL+API_VERSION+API_RESOURCE_REGISTER;
export const API_URL_UPDATEACCOUNT = API_URL+API_VERSION+API_RESOURCE_UPDATEACCOUNT;
export const API_URL_ACCOUNT = API_URL+API_VERSION+API_RESOURCE_ACCOUNT;
export const API_URL_LOGIN = API_URL+API_VERSION+API_RESOURCE_LOGIN;
export const API_URL_RESETPASSWORD = API_URL+API_VERSION+API_RESOURCE_RESETPASSWORD;
export const API_URL_FORGOTPASSWORD = API_URL+API_VERSION+API_RESOURCE_FORGOTPASSWORD;
export const API_URL_CHANGEPASSWORD = API_URL+API_VERSION+API_RESOURCE_CHANGEPASSWORD;
export const API_URL_ACCOUNTACTIVATE = API_URL+API_VERSION+API_RESOURCE_ACCOUNTACTIVATE;
export const API_URL_REDIRECTWEB = API_URL+API_VERSION+API_RESOURCE_REDIRECTWEB;

export const API_HEADERS_GET = {
    method: 'GET',
    headers: {
        'Content-Type': 'application/json',
        'b68e38092d954e389bcb88bfbd94b832': API_KEY
    }
};

export const API_HEADERS_GET_JSON = {
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }
};

export const API_HEADERS_POST = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json'
    }
};

//https://purecatamphetamine.github.io/country-flag-icons/3x2/index.html
export const CURRENCY_OPTIONS = {
    pe: {
        src: ImgPe,
        label: "PEN",
        currency: "S/",
        currency_text: "soles",
        name: "Perú",
        flag: 1
    },
    co: {
        src: ImgCo,
        label: "COL",
        currency: "$",
        currency_text: "pesos",
        name: "Colombia",
        flag: 0
    },
    cl: {
        src: ImgCl,
        label: "CL",
        currency: "$",
        currency_text: "pesos",
        name: "Chile",
        flag: 0
    },
    mx: {
        src: ImgMx,
        label: "MX",
        currency: "$",
        currency_text: "pesos",
        name: "Mexico",
        flag: 0
    },
    us: {
        src: ImgUs,
        label: "USD",
        currency: "$",
        currency_text: "dólares",
        name: "Estados Unidos",
        flag: 0
    }
};

export const DOLARTI_LOGO = DolartiLogo;

export const FILTER_OPTIONS = {
    up: {
        class: 'pi pi-sort-numeric-down',
        fn: {
            compra: (a, b) => a.dolar_compra - b.dolar_compra,
            venta: (a, b) => a.dolar_venta - b.dolar_venta
        }
    },
    down: {
        class: 'pi pi-sort-numeric-up-alt',
        fn: {
            compra: (a, b) => b.dolar_compra - a.dolar_compra,
            venta: (a, b) => b.dolar_venta - a.dolar_venta
        }
    },
    default: {
        class: 'pi pi-sort-alt',
        fn: {
            compra: (a, b) => a.id_random - b.id_random,
            venta: (a, b) => a.id_random - b.id_random
        }
    }
};



export const MESSAGE_ALERT = {
    ERROR_CLIENTE:              ['alert-danger','Credenciales invalidos',''],
    ERROR_SERVIDOR:             ['alert-danger','Error en el servidor',''],
    ERROR_PASSWORD_DIFF:        ['alert-danger','Error validación','La nueva contraseña no coincide'],
    LOGIN: {
        USUARIO_NO_EXISTE:      ['alert-danger','Usuario no existe',''],
        CONTRASENA_ERRONEO:     ['alert-warning','Contraseña erroneo',''],
        CREDENCIALES_CORRECTOS: ['alert-success','Credenciales correctos',''],
    },
    RESETPASSWORD: {
        CORREO_ERRONEO:         ['alert-danger','Correo no existe',''],
        CORREO_CORRECTO:        ['alert-success','Link enviado a tu correo',''],
    },
    REGISTER: {
        CORREO_EXISTE:          ['alert-danger','Correo ya está registrado',''],
        EXITO_REGISTRO:         ['alert-success','Cuenta creada correctamente',''],
    },
    UPDATEACCOUNT: {
        NO_EXISTE_USUARIO:      ['alert-danger','La sesión ha caducado',''],
        EXITO_ACTUALIZACION:    ['alert-success','Se actualizo correctamente',''],
    }
}

export const ALERT = (status, title) =>  {
    var alert;
    switch (status) {
        case 'OK':
            alert = ['alert-success',title,''];
            break;
        case 'WARNING':
            alert = ['alert-danger',title,''];
            break;
        default:
            alert = ['alert-danger',title,'Surgio un error'];
            break;
    }
    return alert;
}