import React from 'react'

const AccountLoading = () =>  {

  return (
    <div>
      <div className="mb-3 row">
        <div className="col-12 my-2">
          <p className="placeholder-glow">
            <span className="placeholder col-12 placeholder-lg"></span>
          </p>
        </div>
        <div className="col-12 my-2">
          <p className="placeholder-glow">
            <span className="placeholder col-12 placeholder-lg"></span>
          </p>
        </div>
        <div className="col-12 my-2">
          <p className="placeholder-glow">
            <span className="placeholder col-12 placeholder-lg"></span>
          </p>
        </div>
        <div className="col-12 my-2">
          <p className="placeholder-glow">
            <span className="placeholder col-12 placeholder-lg"></span>
          </p>
        </div>
        <div className="gap-2 mt-3">
          <a href="#" tabindex="-1" className="btn btn-primary disabled placeholder col-4" aria-hidden="true"></a>
        </div>
      </div>
    </div>
  )

}

export default AccountLoading