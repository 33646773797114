import React from 'react'

const WelcomeLoading = () =>  {

  return (
    <>
      <div className="row align-items-center">

        <div className="col-12">
            <div className="row">
              <div className="col-12 px-0 border rounded-start">
                <div className="row px-3 mb-3">
                  <div className="col-12 placeholder-glow">
                    <span className="placeholder col-2 placeholder-xs"></span>
                  </div>
                  <div className="col-2 placeholder-glow">
                    <span className="placeholder col-12 placeholder-lg"></span>
                  </div>
                  <div className="col-6 placeholder-glow">
                    <span className="placeholder col-12 placeholder-lg"></span>
                  </div>
                  <div className="col-4 placeholder-glow">
                    <span className="placeholder col-12 placeholder-lg bg-primary"></span>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div className="col-12 mt-3">
            <div className="row">
              <div className="col-12 px-0 border rounded-start">
                <div className="row px-3 mb-3">
                  <div className="col-12 placeholder-glow">
                    <span className="placeholder col-2 placeholder-xs"></span>
                  </div>
                  <div className="col-2 placeholder-glow">
                    <span className="placeholder col-12 placeholder-lg"></span>
                  </div>
                  <div className="col-6 placeholder-glow">
                    <span className="placeholder col-12 placeholder-lg"></span>
                  </div>
                  <div className="col-4 placeholder-glow">
                    <span className="placeholder col-12 placeholder-lg bg-secondary"></span>
                  </div>
                </div>
              </div>
            </div>
        </div>

        <div className="col-12 placeholder-glow mt-3">
          <span className="placeholder col-12 placeholder-sm"></span>
          <span className="placeholder col-12 placeholder-xs"></span>
        </div>

      </div>

    </>
  )

}

export default WelcomeLoading