import * as ENV from './Config.js';
import React from 'react'

const Footer = ({pais}) => (
  <>

    <footer className="bg-dark pt-5 pt-md-6">
      <div className="container pt-3 pt-md-0">
        <div className="row pb-3">
          <div className="col-md-12 pb-3 pb-md-0 mb-4 text-center">
            <a className="mb-3" href={"/"+pais}>
              <img src={ENV.DOLARTI_LOGO} className="img-fluid" style={{maxHeight: "80px"}}/>
            </a>
            <p className="fs-sm text-light opacity-60 pb-2 pb-sm-3">
              
            </p>
            <a className="btn btn-outline-light m-2" href="https://www.facebook.com/DolartiDivisas/" target="_blank" atr-html-gtm-id="ir-facebook">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a className="btn btn-outline-light m-2" href="https://twitter.com/dolartidivisas/" target="_blank" atr-html-gtm-id="ir-twitter">
              <i className="fab fa-twitter"></i>
            </a>
            <a className="btn btn-outline-light m-2" href="https://www.linkedin.com/company/DolartiDivisas/" target="_blank" atr-html-gtm-id="ir-linkedin">
              <i className="fab fa-linkedin-in"></i>
            </a>
          </div>
        </div>
        <hr className="my-0 text-white"/>
        <div className="row align-items-center mt-3 py-4">
          <div className="col-md-6 order-md-2 text-md-end mb-3">
            <ul className="list-inline fs-sm mb-0">
              <li className="list-inline-item"><a className="nav-link link-light" href={"/"+pais+"/preguntas"}>Preguntas</a></li>
              <li className="list-inline-item"><a className="nav-link link-light" href={"/"+pais+"/privacidad"}>Privacidad</a></li>
            </ul>
          </div>
          <div className="col-md-6 order-md-1 mb-3">
            <p className="fs-sm mb-0">
              <span className="text-light me-1">
                © Todos los derechos reservados 
              </span>
              <a className="link-light" href={"/"+pais} rel="noopener">
                Dolarti
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>

  </>
)

export default Footer