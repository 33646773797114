import React from 'react'
import ReactDOM from 'react-dom'
import App from './components/App'
import 'bootstrap/dist/css/bootstrap.min.css'
import '@popperjs/core'
//import 'bootstrap/dist/js/bootstrap.min.js'
//import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import './custom.scss';
import 'bootstrap-icons/font/bootstrap-icons.css'
import 'primeicons/primeicons.css'
import '@fortawesome/fontawesome-free/css/all.css'

const container = document.getElementById('root');

ReactDOM.render(<App/>, container);
