import React from 'react'
import Footer from '../components/Footer'
import Error from '../components/Error'

const NotFound = () => {
	return (
    	<div style={{height: "100vh"}}>
            <Error/>
        </div>
	)
}

export default NotFound