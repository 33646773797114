import * as ENV from './Config.js';
import { Redirect, withRouter, useHistory } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Alert from './Alert'
import ButtonSubmit from './ButtonSubmit'


const AccountActivate = ({pais, tokenactivate}) =>  {

    let history = useHistory();

    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    if (tokenactivate.length <= 1) {
      history.push("/pe/");
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (tokenactivate.length > 0) {
          setLoading(true);
          ENV.API_HEADERS_POST.body = JSON.stringify([{
            hash_usuario: tokenactivate
          }]);
          fetch(ENV.API_URL_ACCOUNTACTIVATE, ENV.API_HEADERS_POST)
          .then(async response => await response.json())
          .then(result => {
            setMessage(ENV.ALERT(result.SP_STATUS, result.SP_MSJ_USER));
            setLoading(false);
          })
          .catch(error => {
            setMessage(ENV.MESSAGE_ALERT.ERROR_SERVIDOR);
            setLoading(false);
          });
        } else {
          setMessage(ENV.MESSAGE_ALERT.ERROR_CLIENTE);
          setLoading(false);
        }
    }

    return (
        <section className="pb-3 pb-md-5 px-3 p-md-0 bg-light">
            <div className="container pt-5">
              <div className="row">
                <div className="col-12 col-md-5 mx-auto">
                  <div className="card px-md-5 py-md-4">
                    <div className="card-body">
                      <h4 className="card-title text-center">Activar Cuenta</h4>
                      <p className="fs-sm text-muted text-center">
                        Pulsa el boton activar para que tu cuenta sea verificada, esto se realiza solo una vez.
                      </p>
                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-12">
                                {
                                  message &&
                                  <Alert message={message} setMessage={setMessage}/>
                                }
                                <div className="d-grid gap-2">
                                    <ButtonSubmit value={'Activar'} loading={loading}/>
                                </div>
                            </div>
                        </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
    )
}

export default AccountActivate