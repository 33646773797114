import Header from '../components/Header'
import Terms from '../components/Terms'
import Footer from '../components/Footer'

const Terminos = ({id}) => {

    return (
        <>
        	<Header pais={id}/>
        	<Terms/>
            <Footer pais={id}/>
        </>
    )
}

export default Terminos