import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import ForgotPassword from '../components/ForgotPassword'
import Footer from '../components/Footer'

const OlvideContrasena = ({id}) => {
	//let { id } = useParams();

    return (
        <>
        	<Header pais={id}/>
            <ForgotPassword pais={id}/>
            <Footer pais={id}/>
        </>
    )
}

export default OlvideContrasena