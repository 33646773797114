import Header from '../components/Header'
import Question from '../components/Question'
import Footer from '../components/Footer'

const Privacidad = ({id}) => {

    return (
        <>
        	<Header pais={id}/>
        	<Question/>
            <Footer pais={id}/>
        </>
    )
}

export default Privacidad