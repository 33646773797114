import * as ENV from './Config.js';
import React, { useState, useEffect } from 'react'

const Alert = ({message, setMessage}) =>  {

  const handleCloseAlert = (event) => {
      setMessage(null);
  }

  return (
    <div className={"alert "+message[0]+" alert-dismissible fade show mt-3 col-12 order-last order-md-first"} role="alert">
      <strong>{message[1]}</strong> {message[2]} 
      <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleCloseAlert}></button>
    </div>
  )
}
export default Alert