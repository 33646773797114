import { useParams } from 'react-router-dom'
import Header from '../components/Header'
import ResetPassword from '../components/ResetPassword'
import Footer from '../components/Footer'

const RestablecerContrasena = ({id}) => {
	let { tokenreset } = useParams();

    return (
        <>
        	<Header pais={id}/>
            <ResetPassword pais={id} tokenreset={tokenreset}/>
            <Footer pais={id}/>
        </>
    )
}

export default RestablecerContrasena