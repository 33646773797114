import * as ENV from './Config.js';
import { Redirect, withRouter, useHistory } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import Cuenta from '../pages/Cuenta'
import Alert from './Alert'
import ButtonSubmit from './ButtonSubmit'


const ResetPassword = ({pais, tokenreset}) =>  {

    let history = useHistory();

    const [contrasena, setContrasena] = useState("");
    const [recontrasena, setRecoctrasena] = useState("");
    const [message, setMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    if (tokenreset.length <= 1) {
      history.push("/pe/");
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        if (contrasena.length > 0 & recontrasena.length > 0) {
          if (contrasena === recontrasena) {
            setLoading(true);
            ENV.API_HEADERS_POST.body = JSON.stringify([{
              token: tokenreset,
              new_contrasena: contrasena
            }]);
            fetch(ENV.API_URL_RESETPASSWORD, ENV.API_HEADERS_POST)
            .then(async response => await response.json())
            .then(result => {
              setMessage(ENV.ALERT(result.SP_STATUS, result.SP_MSJ_USER));
              setLoading(false);
              setContrasena("");
              setRecoctrasena("");
            })
            .catch(error => {
              setMessage(ENV.MESSAGE_ALERT.ERROR_SERVIDOR);
              setLoading(false);
            });
          } else {
            setMessage(ENV.MESSAGE_ALERT.ERROR_PASSWORD_DIFF);
            setLoading(false);
          }
        } else {
          setMessage(ENV.MESSAGE_ALERT.ERROR_CLIENTE);
          setLoading(false);
        }
    }

    return (
        <section className="pb-3 pb-md-5 px-3 p-md-0 bg-light">
            <div className="container pt-5">
              <div className="row">
                <div className="col-12 col-md-5 mx-auto">
                  <div className="card px-md-5 py-md-4">
                    <div className="card-body">
                      <h4 className="card-title text-center">Restablecer contraseña</h4>
                      <p className="fs-sm text-muted text-center">
                        Ingresa tu nueva contraseña segura
                      </p>
                        <form className="row g-3" onSubmit={handleSubmit}>
                            <div className="col-12">
                                {
                                  message &&
                                  <Alert message={message} setMessage={setMessage}/>
                                }
                                <div className="mb-3 row">
                                    <div className="col-12 my-2">
                                        <div className="form-floating">
                                          <input type="password" className="form-control" value={contrasena} onChange={(e) => setContrasena(e.target.value)} required/>
                                          <label>Nueva contraseña</label>
                                        </div>
                                    </div>
                                    <div className="col-12 my-2">
                                        <div className="form-floating">
                                          <input type="password" className="form-control" value={recontrasena} onChange={(e) => setRecoctrasena(e.target.value)} required/>
                                          <label>Repetir contraseña</label>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid gap-2">
                                    <ButtonSubmit value={'Restablecer'} loading={loading}/>
                                </div>
                            </div>
                        </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
        </section>
    )
}

export default ResetPassword