import { useParams } from 'react-router-dom'
import { getToken } from '../components/Utils/Common'
import * as ENV from '../components/Config.js';

const VisitarPagina = () => {
	let { url, tracking } = useParams();

    async function handleRedirect() {
    	if (getToken()) {
    		ENV.API_HEADERS_POST.headers['Authorization'] = "Bearer "+getToken();
    	}
		ENV.API_HEADERS_POST.body = JSON.stringify([{
		  id_tracking_tce: tracking
		}]);
		await fetch(ENV.API_URL_REDIRECTWEB, ENV.API_HEADERS_POST)
		.then(response => response.json())
		.then(result => {
			window.location.href = atob(url);
		})
		.catch(error => {});
    }

	(async () => {
		handleRedirect();
	})()

    return (
    	<p className="text-start">
    		...Redirigiendo a {atob(url)}
    	</p>
    )
}

export default VisitarPagina