import * as ENV from './Config.js';
import React, { useState, useEffect } from 'react'

const ButtonSubmit = ({value, loading, gtm_value=''}) =>  {

	return (
		<button type="submit" className="btn btn-primary" disabled={(loading) ? 'disabled' : ''} atr-html-gtm-id={gtm_value}>
		{ (loading) ? (<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>) : '' }
		{ (loading) ? 'Loading...' : value }
		</button>
	)
}

export default ButtonSubmit